.first-widget-btn {
    display: flex;
    text-align: center;
    position: absolute;
    width: 329px !important;
    height: 267px !important;
    left: 40%;
    top: 40%;
    background-color: #EDF2F5 !important;
    border: 1px solid rgba(51, 64, 75, 0.15);
    .MuiSvgIcon-root {
        width: 70px;
        height: 70px;
        color: #33404B;
    }
    .MuiTypography-root {
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        text-align: center;
        color: #33404B;
        padding-top: 30px;
    }
    .MuiCardActionArea-focusHighlight{
        opacity: 0 !important;
    }
}

.widget-tools {
    z-index: 1;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: absolute;
    background: rgba(0, 0, 0, 0.25);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.08), -2px -2px 7px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 0px 4px;
}

.video-topbar {
    justify-content: space-between;
    align-items: center;
    background-color: #545454;
}

.video-label {
    font-family: Avenir !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    margin-left: 20px !important;
    color: #FFD447;
}