.toolbar-action-icon {
    color: white !important;
    height: 50px;
    width: 50px;
    border-radius: 0 !important;
}

.active-position-save-icon {
    background-color: #33404B !important;
}

.active-size-sidebar-icon {
    background-color: white !important;
    color: #33404B !important;
}

.size-side-bar {
    position: absolute;
    left: -301px;
    width: 300;
    z-index: 1;
    height: calc(100vh - 110px);
    border: 1px solid #d7d7d7;
    border-left: none;
    border-top: none;
    transition: width 150ms ease-in-out !important;
    font-size: 12px !important;
    border-radius: 0 !important;
    top: 50px;
  }
  
  .size-side-bar-extended {
    width: 300px;
    position: absolute;
    left: 0;
    z-index: 1;
    height: calc(100vh - 110px);
    border: 1px solid #d7d7d7;
    border-left: none;
    border-top: none;
    transition: width 150ms ease-in-out !important;
    overflow: auto;
    border-radius: 0 !important;
    top: 50px;
    padding: 25px;
    padding-top: 20px !important;
}

.side-bar-max-height {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
}

.box-style {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.checkbox-label, .delay-slider-label {
    .MuiTypography-root {
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 900;
        font-size: 12px;
        color: #3C3C3B;
    }
    .MuiCheckbox-root {
        color: #C8C8C8;
    }
    .MuiCheckbox-root.Mui-checked {
        color: #256EFF;
    }
}

.delay-slider-label {
    gap: 8px;
    margin-right: auto !important;
    margin-left: 30px !important;

    input, span {
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;    
    }

    input {
        padding: 0;
        max-width: 50px;
    }
}

.delay-colors {
    padding-left: 30px;
    font-size: 12px;
    font-family: 'Avenir';

    div.color-input {
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            border: none;
            background: inherit;
            cursor: pointer;
        }
    }
}

.slider {
    margin-left: 12px;
    color: #256EFF !important; 
    margin-bottom: 0px !important;
    margin-top: -10px !important;
    margin-left: 40px;
    .MuiSlider-markLabel {
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        text-align: right;
        color: #256EFF;
    }
}

.MuiSlider-root {
    width: 190px !important;
}

.slider-disabled {
    color: #E9F1FF !important;
    .MuiSlider-markLabel {
        color: #E9F1FF;
    }
}


.warning-alert-sign {
    color: #EF7C29;
}

.stream-alert {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px;
    height: 35px;
    margin-right: 5px;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    .MuiTypography-root {
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: white;
    }
}

.error-alert {
    background-color: #FC4646;
    border: 1px solid #FC4646;
}

.warning-alert {
    background-color: #EF7C29;
    border: 1px solid #EF7C29;
}

.alert-badge {
    margin: 0 17px;
    color: black;
    .MuiBadge-badge {
        background-color: white;
    }
}
