.camera-view {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .tabs > div > div {
    overflow-y: auto;
  }

  .camera-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #545454;
    height: 40px;

    span.camera-label {
      font-weight: 400;
      font-size: 15px !important;
      margin-left: 20px;
      color: #ffd447;
    }

    button.camera-fullscreen-button {
      background: transparent;
      border: none;
      color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .image-container {
    display: flex;
    flex-direction: column;

    img {
      max-width: 100%;
      height: auto;
    }
  }

}