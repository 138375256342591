.input-label {
    font-size: 12px !important;
    align-items: center;
    color: #8A8A8A !important;
}
  
.input-label-warning {
    color: #FC5130 !important;
}

.input-field {
    width: 100%;
    > input {
      border: 1px solid #A4BBDF;
      border-radius: 4px;
      padding: 5px 8px !important;
    }
    .MuiInputBase-input:focus {
      border: 1px solid #256EFF;
    }
}
  
.input-field-warning {
    > input {
      border: 1px solid #FC5130;
    }
    .MuiInputBase-input:focus {
        border: 1px solid #FC5130;
      }
}
  
.input-field::after, .input-field::before {
      border: none !important;
}

.error-icon {
    width: 0.75em !important;
    margin-right: 4px;
    height: 0.65em !important;
    color: #fc5130;
}

.textarea-field {
    width: 100%;
    .MuiOutlinedInput-root {
        border: 1px solid #A4BBDF;
        border-radius: 4px;
        padding: 5px 8px !important;
    }
    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
    .Mui-focused {
        border: 1px solid #256EFF;
    }
}