.modal-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 16px;
  width: 400px;

  .modal-widget-title {
    font-size: 24px;
    font-weight: 500;
    color: #256eff;
    text-align: left;
    margin-right: auto;
  }

  .modal-widget-subtitle {
    font-weight: 500;
    font-size: 1.25rem;
    color: #333;
  }

  & > div {
    width: 100%;
  }

  .actions {
    display: flex;
    padding-top: 16px;
    gap: 8px;
  }
}