.alert-notification {
  border: 1px solid #FFFFFF99;
  border-radius: 20px;
  background: #242830;
  padding: 16px;
  display: flex;
  gap: 8px;

  &.secondary {
    div.logo svg path {
      fill: #24b537;
    }
  }

  .logo {
    background: #256EFF26;
    padding: 12px;
    border-radius: 12px;
  }

  .content {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;

    .title {
      font-weight: 800;
      font-size: 16px;
      display: flex;
      justify-content: space-between;

      button {
        background: none;
        border: none;
        color: #FFFFFF;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

    }

    .hour {
      color: #B6B6B6;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}