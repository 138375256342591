.tab-button {
    width: 65px;
    height: 65px;
    margin-left: 15px !important;
    .MuiButtonBase-root{
        color: rgba(255, 255, 255, 0.5);
        width: 100%;
        height: 100%;
        padding: 20px;
    }
    .MuiButtonBase-root:focus {
        outline: none;
    }
}

.active-tab {
    background-color: #545454;
    .MuiButtonBase-root {
        color: white !important;
    }
}

.perimeter-tab {
    font-weight: 900 !important;
    color: white;
    font-family: 'Avenir' !important;
    height: 100%;
    cursor: pointer;
}

.dashboard-tab {
    color: white;
    font-family: 'Avenir' !important;
    height: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 21px 28px;
    margin-left: 0 !important;
    .MuiSvgIcon-root {
        color: #C4C4C4;
        margin-left: 10px;
        font-size: 16px;
    }
}

.divider {
    border-color: #797979 !important;
    height: 65px !important;
}