.menu-button {
    text-transform: none !important;
    width: 100% !important;
    justify-content: flex-start !important;
    font-size: 16px !important;
    font-weight: 400;
    font-family: 'Avenir' !important;
    color: #3C3C3B !important;
    background-color: rgba(0, 0, 0, 0) !important;
    &:hover {
    background-color: rgba(0, 0, 0, 0) !important;
    }
    &:active {
    background-color: rgba(0, 0, 0, 0) !important;
    }
    &:focus {
    background-color: rgba(0, 0, 0, 0) !important;
    }
}

.menu-button:hover {
  cursor: pointer
}

.perimeter-card {
  background-color: #E8E7E5 !important;
  border-radius: 8px !important;
  margin: 10px !important;
  > button:focus {
    outline: none;
  }
}

.perimeter-card-boxshadow {
  box-shadow: 0px -200px 75px -129px rgba(0,0,0,0.62) inset !important;
}

.modal-title {
  color: #256EFF;
  min-width: 400px;
  font-size: 24px !important;
  font-weight: 400;
}

*:focus {
  outline: none !important;
}