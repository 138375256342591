.dashboard-card {
  background-color: #FFFFFF !important;
  border: 1px solid #EDEDED;
  border-radius: 12px;
  margin: 10px !important;
  > button:focus {
    outline: none;
  }
}

.card-content {
  height: 60%;
  display: flex;
  flex-direction: column;
  .MuiGrid-root {
    display: flex;
    justify-content: space-between;
  }
}

.card-label {
  font-weight: 900 !important;
  font-size: 16px !important;
  color: #262626;
}

.card-description {
  color: #838383;
  font-size: 14px !important;
  margin-top: 16px !important;
}

.card-actions {
  justify-content: end;
  padding: 24px !important;
  .MuiChip-root {
    background-color: #F0F0F0;
    border-radius: 4px;
    margin-right: 19px;
    font-weight: 400;
    font-size: 10px;
    color: #666666;
  }
}

.actions-menu-btn {
  color: #3C3C3B !important;
  text-transform: none !important;
}

.actions-menu-btn:hover {
  background-color: #f5f5f5 !important;
}

.actions-menu-btn:focus {
  outline: none;
}

.dashboard-table{
  .MuiTableCell-root {
    border-bottom: none  !important;
  }
  .MuiTableHead-root{
    .MuiTableRow-root {
      .MuiTableCell-root {
        font-weight: 400;
        font-size: 12px;
        color: #868686;
      }
      .MuiSvgIcon-root {
        width: 12px;
        height: 12px;
        margin-left: 4px;
      }
    }
  }
  .MuiTableBody-root{
    .MuiTableRow-root {
      .MuiTableCell-root {
        font-weight: 400;
        font-size: 16px;
        color: #3C3C3C;
      }
    }
  }
}