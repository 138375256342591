body {
  min-height: unset !important;
  background:  #F7F7F7 !important;
}

.stream-state-tx {
  display: flex;
  align-items: center;
  font-family: 'Avenir', 'Arial', sans-serif;
  font-weight: lighter;
  font-size: 15px;
  letter-spacing: 0.05px;
}

.perimeter-zone-titles {
  display: flex;
  align-items: center;
  font-family: 'Avenir', 'Arial', sans-serif;
  font-weight: bolder;
  font-size: 15px;
  letter-spacing: 0.05px;
  text-transform: uppercase;
}

.tco-red {
  color: #E41F13;
}

.tco-yellow {
  color: #FFD447;
}

.cartouche-train-number {
  font-family: 'Avenir', 'Arial', sans-serif;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.05px;
}

.cartouche-text {
  font-family: 'Avenir', 'Arial', sans-serif;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
}

.cluster-pointcount {
  display: flex;
  align-self: center;
  font-size: 15px;
  font-weight: bolder;
  color: #fff;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.column-gap {
  column-gap: 15px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.cards-perimeters {
  display: flex;
  align-items: center;
  align-content: center;
  width: 200px;
  height: 200px;
  margin: 20px;
}

.card-header {
  font-size: larger;
  text-align: center;
  width: 100%;
  color: #242c31;
  font-weight: bold;
  font-size: 20px;
  border-radius: 10px 10px 0px 0;;
}

.cluster-blocks {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  background-color: #242c31;
  border: none;
  padding: 0px;
  z-index: 100 !important;
}

.cartouche-popup .mapboxgl-popup-content {
  max-width: none;
  background-color: #242c31;
  border-radius: 10px;
  padding-top: 12px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

.cartouche-popup.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #242c31;
}
.cartouche-popup.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  border-top-color: #242c31;
}
.cartouche-popup.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #242c31;
}
.cartouche-popup.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #242c31;
}
.cartouche-popup.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  border-bottom-color: #242c31;
}
.cartouche-popup.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #242c31;
}
.cartouche-popup.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #242c31;
}
.cartouche-popup.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #242c31;
}

.mastheader,
.toolsbar,
.mastcontainer {
  transition: 0.3s ease-in-out;

  .edit-guide {
    padding: 8px;
    color: white;
    background: #0088ce;
    border: none;
    border-radius: 50%;

    &:hover {
      background: #005f8b;
    }
  }

  .see-guide {
    border-radius: 12px;
    padding: 12px;
  }

  .see-message {
    padding: 12px;
    background: transparent;
    border: none;

    &:hover, &:focus {
      background: #545454;
    }
  }
}

.mastcontainer {
  padding-top: 65px !important;
  padding-left: 0 !important;
}

.mastcontainer-map {
  padding-top: 105px !important;
  height: 100vh;
}

.mastcontainer.fullscreen {
  padding-top: 0 !important;
  padding-left: 0 !important;
}

.toolsbar {
  top: 65px !important;
  height: 50px !important;
  background-color: #545454 !important;
}

.toolsbar.fullscreen {
  top: 0 !important;
}

.mastheader.fullscreen {
  top: -65px !important;
}

.spinner-style {
  position: absolute;
  left: 50%;
  top: 40%;
  height: 3rem;
  width: 3rem;
}

.add-bt-zone {
  width: 100px;
  height: 80px;
  border-radius: 0 !important;
  font-size: 25px !important;
  font-weight: bold !important;
}

.add-bt-zone:focus {
  outline: 1px solid !important;
}

.btn-fullscreen {
  font-size: 2rem !important;
  z-index: 1;
  padding: 0.125rem 0.25rem !important;
  background-color: #545454 !important;
}

.btn-fullscreen:hover, .btn-fullscreen:focus {
  background-color: #545454 !important;
  color: white !important;
}

.btn-delete-zone {
  font-size: 1.5rem !important;
  padding: 0 0.125rem !important;
  border-radius: 50% !important;
  width: 2rem;
  height: 2rem;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.btn-update-zone {
  font-size: 1.5rem !important;
  padding: 0 0.125rem !important;
  border-radius: 50% !important;
  width: 2rem;
  height: 2rem;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.clock-style {
  right: 0.5rem;
  height: 3.375rem !important;
  justify-content: center;
  .current-date {
    padding-top: 0.1rem;
  }
}

.clock-style.fullscreen {
  top: 0.5rem !important;
  position: absolute;
  height: 1.75rem !important;
}

.clock-style-activezone {
  top: 5rem !important;
  height: 1.75rem !important;
}

.zone-dashboard {
  padding-top: 3.5rem;
  background-color: rgb(236, 234, 234);
}

.zone-dashboard.fullscreen {
  padding-top: 2.5rem;
}

.lock-button {
  font-size: 1.5rem !important;
  padding: 0 0.125rem !important;
  border-radius: 50% !important;
  width: 2rem;
  height: 2rem;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.add-widget-button {
  font-size: 1.5rem !important;
  padding: 0 0.125rem !important;
  border-radius: 50% !important;
  width: 2rem;
  height: 2rem;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.widget-label {
  position: absolute;
  z-index: 1;
  padding: 1rem;
  color: #FFD447;
  pointer-events: none;
}

.bt-update-widget {
  position: absolute;
  z-index: 2;
  font-size: 1.5rem !important;
  padding: 0 0.125rem !important;
  border-radius: 50% !important;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  right: 4.5rem;
  top: 0.5rem;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item img {
  pointer-events: none;
  user-select: none;  
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: #9FCC2E;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.topbar-appname {
    font-weight: 400;
    font-size: 16px;
    color: white;
    font-family: 'Avenir';
    margin-left: 10px;
    text-transform: uppercase;
    height: 100%;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
  margin-top: 10px;

  &.fullscreen {
    top: 40px;
  }
}

.custom-attribution {
  display: flex;
  height: 100%;
  align-items: flex-end;
}

.mapboxgl-ctrl-attrib-inner {
  font-size: 13px;
  font-family: 'Avenir', 'Arial', sans-serif;
}


.map-infos {
  position: absolute;
  right: 10px;
  top: 25%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  .navigation-control-position {
    position: static !important;
    width: fit-content;
  }


  .zoom-display {
    font-size: 13px;
    color: #242c31;
    background: #fff;
    padding: 5px;
    border-radius: 5px;
  }
}
