.upload-guide {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    span {
      font-size: 24px;
      font-weight: 800;
    }

    button {
      background: none;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      padding: 8px;

      &:hover, &:focus {
        background: #f5f5f5;
      }
    }
  }

  .dropzone {
    border: 2px dashed #256EFF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 350px;
    justify-content: center;
    align-items: center;
    gap: 16px;

    &.has-files {
      border: 2px dashed #a13cc9;
    }


    p, h4 {
      margin: 0;
    }

    svg.icon {
      font-size: 64px;
      color: #256EFF;
    }

    .upload-btn {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;

      button {
        display: flex;
        gap: 8px;
        padding: 18px 32px;
      }

    }
  }

  .file-selected {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 24px;

    .file {
      color: #256EFF;
      display: flex;
      width: 100%;
      gap: 4px;
      align-items: center;

      button {
        background: none;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        padding: 0;
        margin-left: auto;
        color: inherit;

        &:hover, &:focus {
          background: #f5f5f5;
        }
      }
    }

    .actions {
      display: flex;
      gap: 16px;
      justify-content: flex-end;

      button {
        padding: 8px 16px;
        border: none;
        border-radius: 32px;
        cursor: pointer;
        font-weight: 500;
        background: #256EFF;
        color: #fff;

        &:hover, &:focus {
          background: #1b4b91;
        }

        &.cancel {
          background: transparent;
          color: #256EFF;

          &:hover, &:focus {
            background: #f5f5f5;
          }
        }
      }
    }
  }
}