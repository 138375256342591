.communication-message {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button.edit-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: #256EFF;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 40px;

    .select-date {
      display: flex;
      align-items: center;
      gap: 20px;
      min-width: max-content;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
}