.alerts-monitor {
  margin-left: 5px;
  margin-right: -10px;

  button.alerts-monitor-icon {
    background: transparent;
    border: none;
    padding: 5px;

    svg {
      color: #222222;
    }

    &:hover {
      background: #8c8c8c;
      border-radius: 100%;
    }
  }

  .alerts-monitor-window {
    position: fixed;
    top: 120px;
    right: 15px;
    width: 500px;
    max-height: 400px;
    overflow: hidden;
    background: #242830;
    padding: 10px;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 12px;

    header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 6px;

      .title {
        display: flex;
        justify-content: flex-start;
        gap: 5px;

        span {
          font-weight: 800;
          font-size: 18px;
        }
      }

      .settings {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        margin-top: -15px;

        .input-checkboxes {
          display: flex;
          align-items: center;
          gap: 5px;

          .input-checkbox {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
        
        .volume-slider {
          margin-left: auto;
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
    }

    .alerts-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow-y: auto;
      padding: 10px 10px;
      max-height: 300px;
    }
  }
}